import React from "react"
import PropTypes from "prop-types"
import uniqueId from "lodash/uniqueId"
import "./ProductPageListing.scss"
import ProductPanel from "../ProductPanel/ProductPanel"

const ProductPageListing = ({ data }) => {
  return (
    <section className="products-listing">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>Produsele - ensys</h2>
            <hr className="divider" />
          </div>
        </div>

        <div className="row">
          {data &&
            data.map(product => (
              <div className="col-md-6 col-lg-4" key={uniqueId("bl_ens_")}>
                <ProductPanel
                  key={product.id}
                  title={product.title || ""}
                  description={product.productFields?.specs || []}
                  price={
                    product.productFields?.price || {
                      value: "-",
                      currency: "-",
                    }
                  }
                  img={product.productFields?.image?.sourceUrl}
                  infoMessage={product.productFields?.infoMessage || ""}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

ProductPageListing.defaultProps = {
  data: [],
}

ProductPageListing.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
}
export default ProductPageListing
