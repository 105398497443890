import React from "react"
import PropTypes from "prop-types"
import { uniqueId } from "lodash"
import InfoIcon from "../InfoIcon/InfoIcon"
import "./ProductPanel.scss"

const ProductPanel = ({ title, img, description, price, infoMessage }) => {
  return (
    <div className="product-panel">
      <h4 className="title">{title}</h4>
      <div
        className="panel-image"
        style={{ backgroundImage: `url('${img}')` }}
      />
      {((description && description.length) || (price && price.value)) && (
        <div className="panel-content row ">
          <div className="panel-description col-6">
            {description &&
              description.length &&
              description.map(d => {
                const { spec } = d
                return <p key={uniqueId("product_")}>{spec}</p>
              })}
          </div>
          <div className="panel-price col-6">
            {price.value && price.currency && (
              <p>{`${price.currency}${price.value}.00`}</p>
            )}
            {infoMessage && <InfoIcon infoMessage={infoMessage} />}
          </div>
        </div>
      )}
    </div>
  )
}

ProductPanel.defaultProps = {
  infoMessage: "",
}

ProductPanel.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  description: PropTypes.array.isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  infoMessage: PropTypes.string,
}

export default ProductPanel
