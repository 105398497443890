/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import Header from "../components/Header/Header"
import ProductPageListing from "../components/ProductPageListing/ProductPageListing"
import ProductPagination from "../components/ProductPagination/ProductPagination"
import Footer from "../components/Footer/Footer"

const ProductsIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, totalPages, currentPage },
}) => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const products = {
    en: data.allWpProductEn,
    ro: data.allWpProductRo,
  }
  const filteredProducts = products[language].nodes

  if (!filteredProducts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />
        <Header />
        <p style={{ marginTop: "5rem", marginBottom: "10rem" }}>
          No products found.
        </p>
        <Footer />
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title="All products" />
      <Header />
      <ProductPageListing data={filteredProducts} />
      <ProductPagination
        nextPagePath={nextPagePath}
        previousPagePath={previousPagePath}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <Footer />
    </Layout>
  )
}

export default ProductsIndex

export const pageQuery = graphql`
  query WordPressProductsArchive($offset: Int!, $postsPerPage: Int!) {
    allWpProductEn: allWpProduct(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: { language: { code: { eq: EN } } }
    ) {
      nodes {
        id
        productFields {
          image {
            sourceUrl
          }
          specs {
            spec
          }
          price {
            currency
            value
          }
        }
        title
        link
      }
    }
    allWpProductRo: allWpProduct(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: { language: { code: { eq: RO } } }
    ) {
      nodes {
        id
        productFields {
          image {
            sourceUrl
          }
          specs {
            spec
          }
          price {
            currency
            value
          }
        }
        title
        link
      }
    }
  }
`
